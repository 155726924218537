import React, { Fragment } from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import * as Theme from "../Theme";
import Player from "../Components/Player";
import ParseQuery from "../Utils/ParseQuery";
const constants = require("../constants");
const ApiProxyFactory = require("../ApiProxy");

const apiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

class CoursePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: null,
      link: null,
      message: "",
      ratio: Theme.ratio,
      mounted: false,
    };
    this.queryParams = ParseQuery(props.location.search);
  }
  async componentDidMount() {
    let { isOfficial } = this.queryParams;
    isOfficial = parseInt(isOfficial, 10); //"0", "1"

    await this._setToken();
    await this._getProduct();

    // if (isOfficial) {
    //   this._getPrivateProduct();
    // } else {
    //   this._getProduct();
    // }

    if (this.queryParams.width && this.queryParams.height) {
      let ratio = this.queryParams.width / this.queryParams.height;
      this.setState({ ratio, mounted: true });
    } else {
      this.setState({ mounted: true });
    }
  }

  render() {
    const { loading, message, product, link, mounted, ratio } = this.state;
    const { token, product_id, isOfficial, width, height } = this.queryParams;

    if (!mounted) {
      return <Wrapper />;
    }

    return (
      <Wrapper>
        <VideoContainer width={width} height={height} ratio={ratio}>
          {!link || loading ? null : !loading && !product_id ? ( //<Spinner />
            <h1 className="center" style={{ color: "white" }}>
              無此課程
            </h1>
          ) : (
            <Player url={link} />
          )}
          <Hint show={message}>{message}</Hint>
        </VideoContainer>
      </Wrapper>
    );
  }

  _setToken = async () => {
    const { token, product_id } = this.queryParams;
    if (token) {
      await apiProxy.setToken(token);
    }
  };

  _getProduct = async () => {
    let { token, product_id, isOfficial } = this.queryParams;
    isOfficial = parseInt(isOfficial, 10); //"0", "1"

    this.setState({ loading: true });
    try {
      if (product_id) {
        const product = await apiProxy.get({
          path: `/api/product/${product_id}`,
        });
        this.setState({
          product,
          link: isOfficial ? product.official_link : product.trailer_link,
        });
      }
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ loading: false });
  };

  _getPrivateProduct = async () => {
    const { token, product_id } = this.queryParams;

    this.setState({ loading: true });
    try {
      if (product_id) {
        const product = await apiProxy.get({
          path: `/api/product/private/${product_id}`,
        });

        this.setState({
          product,
          link: product.official_link,
        });
      }
    } catch (ex) {
      if (ex.status == 403) {
        this.setState({ message: "權限不足" });
      }
      console.warn(ex);
    }
    this.setState({ loading: false });
  };
}

const Wrapper = styled.div`
  background-color: black;

  min-height: calc(100vh);
  width: 100%;
`;

const VideoContainer = styled.div`
  background-color: black;
  color: white;

  position: relative;
  padding-top: ${props => 100 / props.ratio}%;

  @media screen and (max-width: 600px) {
  }

  & .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Hint = styled.div`
  padding: 10px 20px;
  display: ${props => (props.show ? "block" : "none")};
  background-color: #fff;
  color: ${Theme.colors.sub};
  border-radius: 20px;
  border: solid 1px ${Theme.colors.sub};

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const Spinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  &::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: rotate 0.6s linear infinite;
  }
`;

export default withPage(CoursePage);
